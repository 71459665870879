<template>
  <div>
    <b-card>
      <form @submit.prevent="AddVisit" id="addVisitForm">
        <div>
          Klient
          <b-form-select
            v-model="selectedCustomerId"
            class="w-100"
            required
          >
            <option
              v-for="customer in customers"
              :key="customer.id"
              :value="customer.id"
            >
              {{ customer.name }}
            </option>
          </b-form-select>
        </div>

        <div>
          Pracownik
          <b-form-select
            v-model="selectedEmployeeId"
            class="w-100"
            required
          >
            <option
              v-for="employee in employees"
              :key="employee.id"
              :value="employee.id"
            >
              {{ employee.user.name }}
            </option>
          </b-form-select>
        </div>

        <div>
          Oferta
          <b-form-select
            v-model="selectedOfferId"
            class="w-100"
            required
          >
            <option
              v-for="offer in offers"
              :key="offer.id"
              :value="offer.id"
            >
              {{ offer.title }}
            </option>
          </b-form-select>
        </div>

        <div>
          data
          <input
            id="date"
            type="date"
            v-model="orderDate"
            class="w-100 form-control"
            required
          />
        </div>

        <div>
          Godzina
          <input
            v-model="Time"
            type="time"
            class="form-control"
            required
          >
        </div>
      </form>

      <router-link
          :to="{ name: 'Schedule' }"
          class="btn btn-primary"
      >
        Powrót
      </router-link>
    </b-card>

    <b-card>
      <button form="addVisitForm" class="btn btn-primary mt-2 w-100">
        dodaj wizytę
      </button>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import {
  BCard, BForm, BFormInput, BFormSelect, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormInput,
    BForm,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
  },
  data() {
    return {
      order: {},
      employees: [],
      customers: [],
      offers: {},
      selectedEmployeeId: null,
      selectedCustomerId: null,
      selectedOfferId: null,
      orderDate: '',
      Time: null,
    }
  },
  mounted() {
    this.FetchData()
  },
  methods: {
    FetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}my-clients`, config).then(response => { this.customers = response.data.clients })
      axios.get(`${process.env.VUE_APP_API_URL}employee`, config).then(response => { this.employees = response.data })
      axios.get(`${process.env.VUE_APP_API_URL}my-offers`, config).then(response => { this.offers = response.data.success })
    },
    AddVisit() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const postData = {
        customer_id: this.selectedCustomerId,
        employee_id: this.selectedEmployeeId,
        offer_id: this.selectedOfferId,
        date: this.orderDate,
        time: this.Time,
      }

      axios.post(`${process.env.VUE_APP_API_URL}Place-order/Business`, postData, config)
        .then(response => {
          Swal.fire('sukces', 'Pomyślnie dodano zamówienie', 'success')
          this.$router.push({ name: 'Schedule' })
        })
    },
  },
}
</script>
